import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { slot: "start" }
const _hoisted_2 = { slot: "end" }



export interface IProps {
  defaultHref?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeaderClean',
  props: {
    defaultHref: {}
  },
  setup(__props: any) {

const props = __props;


return (_ctx: any,_cache: any) => {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, { class: "c-app-header-clean" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, { class: "ion-no-padding" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "left", {}, () => [
              _createVNode(_component_ion_buttons, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    class: "c-app-header-clean__back-button",
                    "default-back": props.defaultHref
                  }, null, 8, ["default-back"])
                ]),
                _: 1
              })
            ])
          ]),
          _createVNode(_component_ion_title, { class: "g-header-title c-app-header-clean__title" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "right")
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}
}

})