import { defineStore } from 'pinia';
import { isDevMode } from '@/helpers/dev';
import { devConfigStorage } from '@/services/stores/dev';
import { isClientError, useRepositories } from '@/services/repositories';
import { useSingleRequestDataWrap } from '@/composables/share/request';
import { CheckAccountData } from '@/services/repositories/models/auth';
import { asyncComputed } from '@vueuse/core';
import { get } from 'lodash';

export interface CachedAuthData {
  checkAccount: CheckAccountData;
}

// export const cachedAuthData = ... ;

export function getTgInitData(): string|null {
  if (isDevMode() && !!devConfigStorage.value.tgInitData) {
    return `TelegramInitData ${devConfigStorage.value.tgInitData}`;
  }

  const initData = get(window, 'Telegram.WebApp.initData', null);
  if (initData) {
    return `TelegramInitData ${initData}`;
  }

  return null;
}

export const useAuthStore = defineStore('authStore', () => {
  const { auth: authRepository } = useRepositories();

  const status = useSingleRequestDataWrap<CheckAccountData, null>({
    initialData: null,
    async request() {
      const { data } = await authRepository.checkAccount();
      return data;
    }
  });

  const isCore12Auth = asyncComputed<boolean|null>(async () => {
    status.isActial.value; // bind computed
    status.data.value;     // bind computed

    try {
      await status.actualize();

      // TODO: Возможно в будущем появиться еще одна проверка

      return true;
    } catch (e: any) {
      if (isClientError(e)) {
        switch (e.status) {
          case 401:
          case 404:
            return false;
        }
      }

      return null;
    }
  }, null, { lazy: true });

  return {
    status,
    isCore12Auth,
  };
});