import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { getTgInitData } from '@/services/stores/auth';
import { cid } from '@/services/router';
import qs from 'qs';
// import { isObject } from 'lodash';
// import camelCaseKeys from 'camelcase-keys';
// import snakeCaseKeys from 'snakecase-keys';

export type AsyncResponse<T = any> = Promise<AxiosResponse<T>>;
export type Response<T = any> = AxiosResponse<T>;
export type ClientInstance = AxiosInstance;
export type ClientError<T = any, D = any> = AxiosError<T, D>;

// TODO:
// - Использовать типизацию от Tg
// - Добавить более продвинутую логику, для передачи токена авторизации

declare global {
  interface Window {
    Telegram:any;
  }
}

export function createClient(baseURL: string): ClientInstance {
  const client = axios.create({
    baseURL,
    paramsSerializer(params): string {
      // Преобразуем все ключи из camelCase в snake_case
      // if (isObject(params)) {
      //   params = snakeCaseKeys(params);
      // }

      return qs.stringify(params, {
        arrayFormat: 'brackets',
      });
    },
  });

  client.interceptors.request.use(
    function (config) {
      const tgInitData = getTgInitData();
      if (tgInitData) {
        config.headers['Authorization'] = tgInitData;
      }

      if (cid) {
        config.headers['X-App-Client-Id'] = cid;
      }

      // WARNING: На данный момент, если данные отсылаются в FormData,
      // то преобразования ключей в snake_case не будет.
      // const isFormDataObject = (config.data instanceof FormData);

      // Преобразуем все ключи из тела запроса из camelCase в snake_case
      // if (isObject(config.data) && !isFormDataObject) {
      //   config.data = snakeCaseKeys(config.data);
      // }

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    function(response) {
      // const data = response.data;

      // Преобразуем все ключи объектов из snake_case в camelCase
      // TODO: Это нужно было для приложения админа, а здесь - не нужно
      // if (isObject(response.data)) {
      //   response.data = camelCaseKeys(data, { deep: true });
      // }

      return response;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

  return client;
}

/**
 * Создаст ошибку типа AxiosError
 *
 * @param response
 * @param message
 * @returns
 */
export function createError(response: Response, message?: string): ClientError {
  const error: Error & Record<string, any> = new Error(message || `Request failed with status code ${response.status}`);

  error.config = response.config;
  error.request = response.request;
  error.response = response;
  error.isAxiosError = true;

  error.toJSON = function toJSON() {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: this.description,
      number: this.number,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: this.config,
      code: this?.code
    };
  };

  return error as AxiosError;
}

/**
 * Проверит является ли ошибка типа AxiosError
 *
 * @param payload
 * @returns
 */
export function isClientError(payload: any): payload is AxiosError {
  return axios.isAxiosError(payload);
}

/**
 * Проверяет является ли ошибка ошибкой сети
 *
 * @param e объект ошибки запроса
 * @returns
 */
export function isNetworkError(e: any) {
  // Увы по другому не узнать
  return e?.message === 'Network Error';
}

export default createClient;
