import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'CCardProgress',
  props: {
  value: {
    type: Number,
    default: 0,
  },

  color: {
    type: String,
    required: false,
  }
},
  setup(__props) {

const props = __props;

const normalizedValue = computed<number>(() => {
  return Math.min(1, Math.max(0, props.value));
});

return (_ctx: any,_cache: any) => {
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { class: "c-card-progress" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        style: _normalizeStyle({ '--c-card-progress-bar-value': (normalizedValue.value * 100) + '%' }),
        class: _normalizeClass({
        'c-card-progress__inner-wrap': true,
        'ion-color': props.color,
        [`ion-color-${props.color}`]: props.color,
      })
      }, null, 6),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}
}

})