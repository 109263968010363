import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "org-short-info" }

import {  } from 'vue'



export default /*@__PURE__*/_defineComponent({
  __name: 'OrgShortInfo',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "org-short-info__name" }, " Мойка заказчик ", -1),
    _createElementVNode("div", { class: "org-short-info__opening-hours" }, " откроемся в 9:00 ", -1)
  ])))
}
}

})