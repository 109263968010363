import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/services/stores/auth';
import { useUserStore } from '@/services/stores/user';
import { computedAsync } from '@vueuse/core';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserHeaderButton',
  setup(__props) {

const router = useRouter();
const authStore = useAuthStore();
const userStore = useUserStore();

const loading = computed<boolean>(() => authStore.status.loading || userStore.profile.loading);

const userTitle = computedAsync<string>(async () => {
  if (!authStore.isCore12Auth) {
    return 'Войти';
  }

  await userStore.actualizeCurrentInformation();
  return userStore.profile.data?.firstName || 'Пользователь';
}, '...');

function clickUserAccountHandler() {
  if (loading.value) {
    return;
  }

  if (authStore.isCore12Auth) {
    router.push({ name: 'account-main' });
  } else {
    router.push({ name: 'account-login' });
  }
}

return (_ctx: any,_cache: any) => {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    class: "user-heaeder-button",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (clickUserAccountHandler()))
  }, {
    default: _withCtx(() => [
      (loading.value)
        ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
            key: 0,
            animated: "",
            style: {"width":"80px","height":"1em"}
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_unref(userTitle)), 1)
          ], 64)),
      _createVNode(_component_ion_icon, { name: "c12c-user-circle" })
    ]),
    _: 1
  }))
}
}

})