import { devlog } from '@/helpers/dev';
import { RemovableRef } from '@vueuse/core';
import { Ref, ref, shallowRef, computed, isRef } from 'vue';

export interface UseRequestWrapOptions<T, D = T> {
  request(): Promise<T>;
  initialData: D|Ref<D>|RemovableRef<D>;
}

export function useSingleRequestDataWrap<T, D = T>(options: UseRequestWrapOptions<T, D>) {
  const data = isRef(options.initialData)
    ? options.initialData
    : ref(options.initialData) as Ref<D|T>;
  
  const loading = ref(false);
  const isActial = ref(false);
  const isFetched = ref(false);
  const error: Ref<any> = shallowRef(null);

  /** Общание последнего запроса (блокирует выполенния еще одного паралельного запроса и позволяет дождаться уже начавшийся) */
  let fetchPromise: Promise<void>|null = null;

  /** Отображает статус обновления данных (при первом запросе будет false, в последующем при загрузке (loading) данных будет true)*/
  const updating = computed(() => isFetched.value && loading.value);

  /**
   * Внутренний запрос, запрашивающий обновление данных.
   * Если будет вызываться во время выполнения другого запроса, то возникнет ошибка.
   */
  async function internalFetch(): Promise<void> {
    if (loading.value) {
      throw new Error('Запрос уже выполняется, повторный запуск без завершения предыдущего - невозможен');
    }

    loading.value = true;

    try {
      data.value = await options.request() as T;

      error.value = null;

      isFetched.value = true;
      isActial.value = true;
      loading.value = false;
    } catch (e: any) {
      error.value = e;
      loading.value = false;
      throw e;
    }
  }

  /**
   * Запрос, для получения последних данных.
   * В случае, если запрос уже выполняется, дождется выполнения предыущего, уже запущенного запроса.
   * 
   * @returns 
   */
  async function fetch(): Promise<void> {
    if (fetchPromise) {
      devlog('Повторный запрос остановлен, дожидаемся обработки предыдущего', fetchPromise);
      await fetchPromise;
      return;
    }

    fetchPromise = internalFetch();

    try {
      await fetchPromise;
      fetchPromise = null;
    } catch (e: any) {
      fetchPromise = null
      throw e;
    }
  }

  /**
   * Проверит актуальность данных (по флагам),
   * и если занные не актуальны, запустит процесс их обновления.
   */
  async function actualize(): Promise<void> {
    if (!isFetched.value || !isActial.value) {
      await fetch();
    }
  }

  return {
    data,
    loading,
    isActial,
    isFetched,
    error,
    fetch,
    actualize,
    updating,

    internalFetch,
  };
}

export interface DefineSingleProcessingOptions<D = any, E = any> {

  /**
   * Если указан true - то во время повторного вызова до завершения процесса выбросит исключение,
   * иначе (false) - вернет обещание уже запушенного процесса
   */
  throwIfProcessing?: boolean;

  /**
   * Перед выполнением процесса очищать старые данные ответа,
   * в противном случае очистит/заменит их после выполнения
   */
  beforeClearData?: boolean;

  /**
   * Перед выполнением процесса очищать старые данные Ошибки,
   * в противном случае очистит/заменит их после выполнения
   */
  beforeClearError?: boolean;

  /**
   * Вызовится в случае успешного выполнения
   * @param data данные пришедшие в запросе
   * @returns 
   */
  successCallback?: (data: D) => void;

  /**
   * Вызовится в случае ошибки
   * 
   * ПРИМЕЧАНИЕ: Не вызывается в случае ошибки, запущенного процесса.
   * Т.е. при повторном вызове start() до окончания процесса выполнения
   * и когда стоит флаг throwIfProcessing = true.
   * 
   * @param err данные ошибки
   * @returns 
   */
  errorCallback?: (err: E) => void;
}

export const DEFINE_SINGLE_PROCESSING_OPTIONS_DEFAULT: Required<DefineSingleProcessingOptions> = {
  throwIfProcessing: true,
  beforeClearData: true,
  beforeClearError: true,

  successCallback: () => { /** Empty */ },
  errorCallback: () => { /** Empty */ },
};

/**
 * Обертка для упрощенной реализации обработки какого-то действия
 * - В случае если процесс уже выполняется, вернет то-же экземпляр обещания, либо выполнит выкинет исключение
 * - Сохранит результат последнего выполнения, либо ошибку
 * - Флаг процесса выполнения
 * 
 * @param requestHandler 
 * @returns 
 */
export function defineSingleProcessing<T extends (...args: any[]) => Promise<any>, E = any>(
  requestHandler: T,
  options: DefineSingleProcessingOptions = {}
) {
  const o: Required<DefineSingleProcessingOptions<Awaited<ReturnType<T>>, E>> = Object.freeze({
    ...DEFINE_SINGLE_PROCESSING_OPTIONS_DEFAULT,
    ...options
  });

  const propcessingPromise = shallowRef<ReturnType<T>|null>(null);
  const propcessing = computed<boolean>(() => !!propcessingPromise.value);
  const error = ref<E|null>(null);
  const data = ref<Awaited<ReturnType<T>>|null>(null);

  function start(...args: any[]) {
    if (propcessingPromise.value) {
      if (o.throwIfProcessing) {
        throw new Error('На данный момент процесс уже выподняется, дождитесь окончания его выполнения');
      }

      return propcessingPromise.value;
    }

    if (o.beforeClearError) {
      error.value = null;
    }

    if (o.beforeClearData) {
      data.value = null;
    }

    propcessingPromise.value = new Promise((resolve, reject) => {
      requestHandler(...args)
        .then(res => {
          data.value = res;
          propcessingPromise.value = null;
          setTimeout(() => o.successCallback(res)); // next tick
          resolve(res);
        })
        .catch(err => {
          error.value = err;
          data.value = null;
          propcessingPromise.value = null;
          setTimeout(() => o.errorCallback(err)); // next tick
          reject(err);
        })
      ;
    });

    return propcessingPromise.value;
  }

  return {
    options: o,
    propcessing,
    error,
    start: start as T,
  };
}