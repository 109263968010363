import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-title" }




export default /*@__PURE__*/_defineComponent({
  __name: 'CTitleBlock',
  props: {
  /** Скорректирвоать вертикальные отступы (visual-fix) */
  correctV: {
    type: Boolean,
    default: true,
  }
},
  setup(__props) {

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({
      'c-title__inner-wrap': true,
      'correct-text-block-indents-v': props.correctV
    })
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}
}

})