import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "c-price" }
const _hoisted_2 = { key: 0 }

import { PropType, computed } from 'vue';
import { preparePrice, PriceFormatOptions } from '@/utils/price';


export default /*@__PURE__*/_defineComponent({
  __name: 'CPrice',
  props: {
  price: {
    type: [Number, String] as PropType<string|number>,
    required: true,
  },
  priceStrike: {
    type: [Number, String] as PropType<string|number>,
    required: false,
  },
  options: {
    type: Object as PropType<PriceFormatOptions>,
    default: () => ({}),
  },

  /** Скорректирвоать вертикальные отступы (visual-fix) */
  correctV: {
    type: Boolean,
    default: true,
  }
},
  setup(__props) {

const props = __props;

const priceFormatted = computed(() => {
  return preparePrice(props.price, {
    currency: '₽',
    ...props.options,
  });
});

const priceStrikeFormatted = computed(() => {
  return preparePrice(props.priceStrike, {
    currency: '₽',
    ...props.options,
  });
});

const hasPriceStrike = computed<boolean>(() => {
  return !!props.priceStrike; // NOTE: Теоритически должно хватать
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({
      'c-price__inner-wrap': true,
      'correct-text-block-indents-v': props.correctV,
    })
    }, [
      _createTextVNode(_toDisplayString(priceFormatted.value) + " ", 1),
      (hasPriceStrike.value)
        ? (_openBlock(), _createElementBlock("s", _hoisted_2, _toDisplayString(priceStrikeFormatted.value), 1))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}
}

})