import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { slot: "start" }
const _hoisted_2 = { slot: "end" }
const _hoisted_3 = { class: "g-header-title c-app-header__title" }

import { onBeforeMount } from 'vue';
import { useStore } from '@/composables/useApp';
import OrgShortInfo from '@/components/org/OrgShortInfo.vue';
import UserHeaderButton from '@/components/user/UserHeaderButton.vue';

export interface IProps {
  defaultBack?: any; // TODO: Definition type
  showTitle?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeader',
  props: {
    defaultBack: {},
    showTitle: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;
const store = useStore();

// TODO: Убрать отсюда в кнопку
onBeforeMount(() => {
  store.user.getCurrentUser();
  store.user.getUserCars();
  store.user.getUserVisits();
});


return (_ctx: any,_cache: any) => {
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, { class: "c-app-header" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, { class: "ion-no-padding" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "left", {}, () => [
              _createVNode(OrgShortInfo, { class: "ion-padding" })
            ])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "right", {}, () => [
              _createVNode(_component_ion_buttons, null, {
                default: _withCtx(() => [
                  _createVNode(UserHeaderButton)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 3
      }),
      (_ctx.showTitle)
        ? (_openBlock(), _createBlock(_component_ion_toolbar, {
            key: 0,
            class: "ion-no-padding"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_ion_back_button, {
                  class: "c-app-header__back-button",
                  "default-href": props.defaultBack
                }, null, 8, ["default-href"]),
                _renderSlot(_ctx.$slots, "default")
              ])
            ]),
            _: 3
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})