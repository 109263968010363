import { AsyncResponse, BaseRepository } from './BaseRepository';
import type { CreateVisitDto, Visit } from '@/services/repositories/models/visit';
import type { UserCarsResponse, User, UserCarBody, UserCar } from '@/services/repositories/models/user';
import type { ErrorResponse } from '@/services/repositories/models/error';

export class UserRepository extends BaseRepository {
  /**
   * Получение текущего пользователя
   *
   * @returns
   */
  getCurrentUser(): AsyncResponse<User> {
    return this.client.get('/v1/telegram/user');
  }

  /**
   * Получение списка автомобилей пользователя
   * 
   * ответы:
   * > 200 - Список автомобилей текущего пользователя telegram
   * > 401 - Неверные данные авторизации
   * > 404 - Пользователь внешнего ресурса не зарегистрирован или автомобиль не найден
   * 
   * @throws {ErrorResponse}
   *
   * @returns
   */
  getUserCars(): AsyncResponse<UserCarsResponse> {
    return this.client.get('/v1/telegram/user/cars');
  }

  /**
   * Создание нового автомобиля для пользователя
   * 
   * ответы:
   * > 200 - Данные созданного автомобиля для текущего пользователя telegram
   * > 401 - Неверные данные авторизации
   * > 404 - Пользователь внешнего ресурса не зарегистрирован или автомобиль не найден
   * 
   * @throws {ErrorResponse}
   * 
   * @param car 
   * @returns 
   */
  createUserCar(car: UserCarBody): AsyncResponse<UserCar> {
    return this.client.post('/v1/telegram/user/cars', car);
  }

  /**
   * Обновление информации об автомобиле
   * 
   * ответы:
   * > 200 - Данные обновленного автомобиля для текущего пользователя telegram
   * > 401 - Неверные данные авторизации
   * > 404 - Пользователь внешнего ресурса не зарегистрирован или автомобиль не найден
   * 
   * @throws {ErrorResponse}
   * 
   * @param id идентификатор авто
   * @param car данные автомобиля
   * @returns 
   */
  updateUserCar(id: string, car: UserCarBody): AsyncResponse<UserCar> {
    return this.client.put(`/v1/telegram/user/cars/${id}`, car);
  }

  /**
   * Удаление авто пользователя
   * 
   * ответы:
   * > 204 - Автомобиль удален для текущего пользователя telegram
   * > 401 - Неверные данные авторизации
   * > 404 - Пользователь внешнего ресурса не зарегистрирован или автомобиль не найден
   * 
   * @throws {ErrorResponse}
   * 
   * @param id идентификатор авто
   * @returns 
   */
  removeUserCar(id: string): AsyncResponse<void> {
    return this.client.delete(`/v1/telegram/user/cars/${id}`);
  }

  /**
   * Получение списка визитов пользователя
   *
   * @returns
   */
  getUserVisits(): AsyncResponse<{ data: Visit[] }> {
    return this.client.get('/v1/telegram/user/cars/visits');
  }

  /**
   * Получение визита по ID
   *
   * @returns
   */
  getVisitById(visitId: string): AsyncResponse<{ data: Visit[] }> {
    return this.client.get(`/v1/telegram/user/cars/visits/${visitId}`);
  }

  /**
   * Создать визит пользователя
   *
   * @returns
   */
  createVisit(payload: CreateVisitDto): AsyncResponse<{ data: Visit }> {
    return this.client.post('/v1/telegram/user/cars/visits', payload);
  }
}
